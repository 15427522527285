<template>
  <v-select
    class="localization-selector"
    :items="supportedLanguages"
    :value="currentLanguage"
    :loading="loadingProfileSetting"
    hide-details
    solo
    prepend-inner-icon="mdi-earth"
    data-cy="localization-selector"
    @change="selectLocalization"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { Trans } from '../../../plugins/Translation'
import { JWT_NAME } from '@/constants/app'

export default {
  computed: {
    ...mapState('client', ['loadingProfileSetting']),
    supportedLanguages () {
      return Trans.supportedLanguages.map(language => language.toUpperCase())
    },
    currentLanguage () {
      return Trans.currentLanguage.toUpperCase()
    }
  },
  methods: {
    ...mapActions('client', ['setClientLocaleSetting']),
    selectLocalization: async function (locale) {
      const lowerCaseLocale = locale.toLowerCase()
      if (localStorage.getItem(JWT_NAME)) {
        await this.setClientLocaleSetting({ clientId: this.$route.params.clientId, locale: lowerCaseLocale })
      }
      const to = this.$router.resolve({ params: { locale: lowerCaseLocale } })
      await Trans.changeLanguage(lowerCaseLocale)
      this.$router.push(to.location)
    }
  }
}
</script>

<style scoped lang="scss">
.localization-selector {
  transform: scale(.7);
  max-width: 7em;
}
</style>
